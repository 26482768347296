export const getUrlForNft = (nft) => {
  let slug = nft.encoded_id;
  const name = nft.name;
  if (name) {
    slug = name.toLowerCase().replace(/\s/g, '-').replace(/#/g, '').substring(0, 30) + '-' + nft.encoded_id;
    try {
      slug = encodeURIComponent(slug);
    } catch (e) {
      slug = nft.encoded_id;
    }
  }
  return `/nfts/${slug}`;
};

export const getUrlForCollection = (collection) => {
  let slug = collection.id;
  const name = collection.name;
  if (name) {
    slug = name.trim().toLowerCase().replace(/\s/g, '-').substring(0, 30) + '-' + collection.id;
    try {
      slug = encodeURIComponent(slug);
    } catch (e) {
      slug = collection.id;
    }
  }
  return `/collections/${slug}`;
};

export const getUrlForProfile = (profile) => {
  let slug = profile.id;
  const name = profile.name;
  const username = profile.username;
  if (username) {
    return `/${username}`;
  } else if (name) {
    slug = name.trim().toLowerCase().replace(/\s/g, '-').substring(0, 30) + '-' + profile.id;
    try {
      slug = encodeURIComponent(slug);
    } catch (e) {
      slug = profile.id;
    }
  }
  return `/profile/${slug}`;
};
